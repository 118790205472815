.cover {
  background-color: #fff;
  cursor: pointer;
  max-height: 55vh;
  max-width: 100%;
  border: 5px solid #ccc;
  transition: transform 0.1s;
  transform: translate(0, 0);
}
.cover:hover {
  z-index: 99999;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, -5px, 0);
}

.doublecover {
  background-color: #fff;
  cursor: pointer;
  max-height: 40vh;
  max-width: 100%;
  border: 5px solid #ccc;
  transition: transform 0.1s;
  transform: translate(0, 0);
}
.doublecover:hover {
  z-index: 99999;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, -5px, 0);
}
