.cardContainer {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  .promptCol {
    width: 50%;
    min-width: 10vw;
    margin-left: 10px;
    margin-right: 10px;
  }

  .promptCol > img {
    width: 100%;
    height: 100%;
  }

  .answerCol {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 100%;
  }

  .answerCard {
    width: 100%;
    min-width: 100px;
    max-width: 20vw;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
