/* custom */
.dashboard-container {
  position: relative;
  height: 100%;
}

.timeline-container {
  display: inline-block;
  width: 100%;
  overflow-y: auto;
}

.hidden {
  display: none !important;
}

.star {
  color: #f9f33f;
}

.bottom-stats {
  font-size: 3.9vh;
  padding-left: 2%;
  padding-top: 1%;
  color: #fff;
  cursor: pointer;
  bottom: 0;
  position: absolute;
  padding-bottom: 2%;
  width: 100%;
  text-align: center;
}

.progressHeader {
  color: #fff;
  text-align: center;
  font-size: 4vh;
}
