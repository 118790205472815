$font-size-base: 1.1rem;

@import "~bootstrap/scss/bootstrap.scss";
@import "~material-icons/iconfont/material-icons.scss";
@import "~animate.css/animate.css";

/* Rules for sizing the icon. */
//TODO use viewport units for all.
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 3vmin;
}
.material-icons.md-60 {
  font-size: 4vmin;
}
.material-icons.md-75 {
  font-size: 7vmin;
}
.material-icons.md-100 {
  font-size: 8vmin;
}
.material-icons.md-125 {
  font-size: 9vmin;
}
.material-icons.md-150 {
  font-size: 25vmin;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

@font-face {
  font-family: "Andika";
  src: url("./fonts/Andika-Regular.ttf");
}

body {
  background-color: #f9e785;
  font-family: "Andika";
}
