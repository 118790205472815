.dashboardWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  font-size: 4vh;
  text-align: center;
}

.selectionWrapper {
  border: 2px solid #ccc;
  border-radius: 25px;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 5vw;
  margin-right: 5vw;
  cursor: pointer;
  height: 36vh;
}

.wallOfFame img {
  width: 100%;
  margin-bottom: 1vh;
}

.timeline img {
  width: 100%;
  height: 100%;
}
