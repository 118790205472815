@import "./../../pages/App/vars.scss";

.alphabetContainer {
  display: flex;
  flex-direction: column;
  font-size: 8.5vh;
  overflow: hidden;
  width: 70%;
}

.musicContainer {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40vh;
  line-height: 40vh;
  & > svg {
    width: 25%;
    height: 25%;
    cursor: pointer;
  }
}

.letter {
  cursor: pointer;
  color: #fff;
  text-align: center;
  transition: color 0.5s ease;
  position: relative;
  width: 7.2vw;
  font-size: 7.8vh;
}

.activeRow {
  & > div {
    color: #0613bf;
  }
}

.activeLetter {
  color: #0613bf;
}

.rowContainer {
  display: flex;
  & > * + * {
    //margin-left: 2vw;
  }
}

.outlineRed {
  color: $red;
}

.outlineGreen {
  color: $green;
}
