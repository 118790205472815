.addButtonContainer {
  background-color: #158b10;
  border-radius: 50%;
  color: #fff;
}
.newUserButtonWrapper {
  cursor: pointer;
}
.addText {
  font-size: 3vh;
  color: #fff;
}
.newButtonContainer {
  margin: 0 auto;
  margin-top: 5vh;
}
