.timelineContainer {
  display: inline-block;
  width: 100%;
  overflow-y: auto;
}
.progressHeader {
  color: #fff;
  text-align: center;
  font-size: 4vh;
}
