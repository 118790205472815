@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.iconImage {
  max-width: 6vw;
  cursor: pointer;
}
.iconText {
  color: #fff;
  margin-top: 5px;
  font-size: 2vw;
}
