.active {
  cursor: pointer;
  border: 10px solid #2096ff;
}
.inactive {
  opacity: 0.3;
}

.cardGroup {
  width: 22vw;
  border: 4px solid #f0ad4e;
  position: relative;
  margin: 0 auto;
  border-radius: 10%;
  background-color: #f9e785;
  .completed {
    position: absolute;
    max-width: 100%;
    max-height: 75%;
    z-index: 999;
    top: 3vh;
  }
  .spiral {
    max-width: 4vw;
    position: absolute;
    z-index: 999;
    filter: drop-shadow(2px 2px 0 #f0ad4e) drop-shadow(-2px 2px 0 #f0ad4e)
      drop-shadow(2px -2px 0 #f0ad4e) drop-shadow(-2px -2px 0 #f0ad4e);
  }

  .imgGroup {
    padding-top: 1.55rem;
    padding-bottom: 0.6rem;
    text-align: center;
    border-radius: 9%;
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  border-radius: 5px;
}

.cardTitle {
  width: 25vw;
  margin: 0.625rem auto 0;
}
.cardTitleText {
  margin: 0 auto;
  width: 75%;
  text-align: center;
  font-size: 2vw;
  background-color: white;
  border: 5px solid #2a6496;
  border-radius: 15px;
  color: #2a6496;
  font-weight: bold;
}
