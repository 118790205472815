.logo {
  max-height: 9vh;
  cursor: pointer;
}
.infoIcon {
  cursor: pointer;
  max-height: 6vh;
}

.gameContainer {
  height: 100%;
  padding: 1.5vmin;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  background-color: #6889bf;
  overflow-y: auto;
  overflow-x: hidden;
}

.materialButton {
  cursor: pointer;
}

.materialButtonText {
  font-size: 2vmax;
  display: inline;
  vertical-align: middle;
}

.materialButtonTextContainer {
  cursor: pointer;
}

.bottomButton {
  height: 8vh;
  cursor: pointer;
}

.profileIcon {
  height: 8vh;
  font-size: 4vh;
  text-align: right;
}
