.glenGuide {
  width: 25vw;
  height: 25vw;
  border: 1px solid black;
  border-radius: 100%;
  background-size: 60%;
  background-color: #cccae0;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99999;
  position: absolute;
  left: 38vw;
  top: 20vh;
  animation-duration: 0.4s;
}

.girl1 {
  background-image: url("./characters/Girl1.png");
}

.girl2 {
  background-image: url("./characters/Girl2.png");
}
