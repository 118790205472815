.certificateWrapper {
  margin-bottom: 4vh;
  margin-top: 4vh;
  padding-left: 2vw;
  padding-right: 2vw;
  text-align: center;
}

.frame {
  cursor: pointer;
  height: 25vh;
  background-color: #ddc;
  border-radius: 2px;
  border: 5vmin solid #eee;
  border-top-color: #ddd;
  border-bottom-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  padding: 1vmin;
  position: relative;
  text-align: center;
  &:before {
    border-radius: 2px;
    bottom: -2vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
    content: "";
    left: -2vmin;
    position: absolute;
    right: -2vmin;
    top: -2vmin;
  }
  &:after {
    border-radius: 2px;
    bottom: -2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    content: "";
    left: -2.5vmin;
    position: absolute;
    right: -2.5vmin;
    top: -2.5vmin;
  }
  & > svg {
    width: 100%;
    height: 100%;
    //border: 3px solid #ccc;
  }
}
