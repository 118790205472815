@import "./../../pages/App/vars.scss";

.textContainer {
  background-color: #dde4ff;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  height: 100%;
  position: relative;
}
.text {
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.sizeStandard {
  height: 6vh;
}

.sizeMd {
  height: 8vh;
}

.sizeXl {
  height: 10vh;
}

.sizeXxl {
  height: 12vh;
}

.sizeXxxl {
  height: 40vh;
}

.bgWhite {
  background-color: #fff;
}

.borderGreen {
  border-color: #1c7430;
}
.borderRed {
  border-color: $red;
}

.colorBlack {
  color: #000;
}

.colorGreen {
  color: #1cb922;
}

@keyframes redToWhiteBgTransition {
  0% {
    background-color: $red;
  }
  100% {
    background-color: #dde4ff;
  }
}

@keyframes greenToWhiteBgTransition {
  0% {
    background-color: $green;
  }
  100% {
    background-color: #dde4ff;
  }
}

.redHighlightAnimation {
  animation-name: redToWhiteBgTransition;
  animation-duration: 1s;
}

.greenHighlightAnimation {
  animation-name: greenToWhiteBgTransition;
  animation-duration: 2s;
}
