@import "./../../pages/App/vars.scss";

.repeatHolder {
  background-color: $background;
  border-style: solid;
  border-width: 5px;
  border-color: $borderColor;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  display: table;
  cursor: pointer;
  padding: 0.5vw;
}

img.repeatAudio {
  cursor: pointer;
  max-width: 3vw;
}
