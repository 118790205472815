.glenStar {
  position: absolute;
  transition: all 2s ease 0s;
  display: none;
  margin-left: auto;
  margin-right: auto;
  top: 30%;
  left: 0;
  right: 0;
  opacity: 1;
  width: 5vw;
  height: 5vw;
  z-index: 9999;
}

.increaseSize {
  width: 18vw;
  height: 18vw;
}

.move {
  display: inherit;
}

.disappear {
  opacity: 0;
  width: 30px;
  height: 30px;
}

.hidden {
  display: none;
}

.certificateImage {
  width: 100%;
}
