.dropOuterBox {
  margin: 0 auto;
  height: 13vh;
  margin-top: 8vh;
  width: 75%;
}

.dropArea {
  background-color: #dde4ff;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  height: 100%;
  font-size: 5vh;
  position: relative;
}

.dropInnerContainer {
  display: flex;
  align-items: center;
  margin-left: 1vh;
  height: 100%;
}
