.cardContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  .promptRow {
    margin-top: 10px;
    min-width: 100px;
    max-width: 30vmin;
  }

  .promptRow > img {
    width: 100%;
    height: 100%;
  }

  .answerRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    margin-right: 6vmin;
    margin-left: 6vmin;
  }

  .answerCard {
    min-width: 100px;
    width: 100%;
    max-width: 20vw;
    margin: 10px;
  }
}
