@import "./../../pages/App/vars.scss";

.alphabetContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.letter {
  color: #fff;
  transition: color 0.5s ease;
  width: 7.2vw;
  font-size: 7.8vh;
}

.activeRow {
  & > div {
    color: #0613bf;
  }
}

.activeLetter {
  color: #0613bf;
}

.rowContainer {
  display: flex;
}
