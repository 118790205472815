.avatarSm {
  max-width: 7vmin;
}
.avatarXs {
  max-width: 3.4vmin;
}
.avatarMd {
  max-width: 7.8vmin;
}
.avatarLg {
  max-width: 9.4vmin;
}
.avatarText {
  color: #fff;
  font-size: 1.5vw;
}
.pointerContainer {
  cursor: pointer;
}
.selected {
  border: 5px solid #000;
  border-radius: 50%;
}
.clearIcon {
  float: right;
  left: 75%;
  top: 0;
  color: #d10035;
  position: absolute;
}
