.isIncorrect,
.isCorrect {
  flex-basis: 50%;
}

.container {
  cursor: pointer;
  position: relative;
}

.checkContainer {
  position: absolute;
  width: 125%;
  z-index: 999;
  height: 100%;
}

.checkContainerInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
