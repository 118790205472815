.active {
  cursor: pointer;
}
.inactive {
  opacity: 0.3;
}

.cardGroup,
.cardGroup::before,
.cardGroup::after {
  /* Add shadow to distinguish sheets from one another */
  border-radius: 10%;
  border: 4px solid #f0ad4e;
  background-color: #f9e785;
}

.cardGroup::before,
.cardGroup::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eee;
}

/* Second sheet */
.cardGroup::before {
  left: 1.2vw;
  top: 0px;
  z-index: -1;
}

/* Third sheet */
.cardGroup::after {
  left: 2vw;
  top: 1px;
  z-index: -2;
}

.cardGroup {
  width: 15vw;
  height: 25vh;
  border: 4px solid #f0ad4e;
  position: relative;
  margin: 0 auto;
  border-radius: 10%;
  background-color: #f9e785;
  outline: 0;
  &.isLarge {
    width: 20vw;
    height: 35vh;
  }
  .completed {
    position: absolute;
    z-index: 999;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: 100%;
    width: 100%;
  }
  .play {
    position: absolute;
    z-index: 999;
    padding-top: 6.5vh;
    padding-bottom: 6.5vh;
    height: 100%;
    width: 100%;
  }
  .spiral {
    max-width: 4vw;
    position: absolute;
    z-index: 999;
    filter: drop-shadow(2px 2px 0 #f0ad4e) drop-shadow(-2px 2px 0 #f0ad4e)
      drop-shadow(2px -2px 0 #f0ad4e) drop-shadow(-2px -2px 0 #f0ad4e);
  }
  .wordGroup {
    height: 100%;
    padding-top: 35px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 5vh;
  }
  .imgGroup {
    padding-top: 1vh;
    padding-bottom: 1vh;
    text-align: center;
    height: 100%;
  }
  .groupImage {
    max-width: 100%;
    max-height: 70%;
    border-radius: 5px;
  }
}
.cardGroupCol {
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    border-radius: 5px;
  }
}
.cardTitle {
  width: 15vw;
  margin: 10px auto 0;
}
.cardTitleText {
  margin: 0 auto;
  width: 75%;
  text-align: center;
  font-size: 2vw;
  background-color: white;
  border: 5px solid #2a6496;
  border-radius: 15px;
  color: #2a6496;
  font-weight: bold;
}
