@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.shelf-container {
  background: url(./bookshelf.png) no-repeat bottom;
  background-size: 100%;
  padding-bottom: 4% !important;
}

.shelf-no-background {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 6vh !important;
}

.slick-list {
  margin-left: 4vw;
  margin-right: 4vw;
}

.slick-arrow {
  width: 75px;
  height: 75px;
}

.slick-slider {
  div {
    &:focus {
      outline: 0;
    }
  }
}
