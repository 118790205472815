@import "./../../pages/App/vars.scss";

.card {
  cursor: pointer;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  padding: 2vh;
  height: 28vh;
  position: relative;
}
.cardSmall {
  max-height: 20vh;
}
.cardLarge {
  height: 32vh;
}
.cardXLarge {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.white {
  background-color: #fff;
}
.blue {
  background-color: #dde4ff;
}
.outlineRed {
  border-color: $red;
  border-width: 10px;
}
.outlineGreen {
  border-color: $green;
  border-width: 10px;
}
.image {
  max-width: 70%;
}
.imageWithText {
  max-height: 100%;
}
.imageWithoutText {
  max-height: 100%;
  max-width: 100%;
}

.textLarge {
  font-size: 5vw;
}

.textNormal {
  font-size: 4.5vh;
}

.textSmall {
  font-size: 1.8vw;
}

.textOnly {
  margin-top: 5vh;
}

.textColorGreen {
  color: $green;
}

.textBold {
  font-weight: bold;
}

.checkIcon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}

.circleContainer {
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: -10px;
  right: -10px;
  z-index: 9999;
}
