.addProfileContainer {
  width: 75%;
  margin: 0 auto;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 2vw;
  color: #fff;
  font-size: 2vw;
  text-align: right;
}
