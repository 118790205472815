@import "./../../pages/App/vars.scss";

.alphabetContainer {
  display: flex;
  flex-direction: column;
  font-size: 8.5vh;
  overflow: hidden;
}

.letter {
  color: #fff;
  transition: color 0.5s ease;
}

.activeLetter {
  color: #0613bf;
}

.rowContainer {
  display: flex;
  & > * + * {
    margin-left: 2vw;
  }
}

.musicIconContainer {
  width: 5vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
}

.musicCircleContainer {
  width: 10%;
  margin-right: 1vw;
  cursor: pointer;
}
