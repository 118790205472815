.dictionaryWord {
  width: 100%;

  &.middle {
    margin-top: 26vh;
  }

  &.mid {
    margin-top: 10vh;
  }

  text-align: center;
  cursor: pointer;

  .wordText {
    color: #000;
    background-color: #ffffff;
    font-size: 3.1vw;
    width: 100%;
    border-style: solid;
    border-width: 5px;
    border-color: #475587;
    border-radius: 20px;
  }
}

.cardContainer {
  text-align: center;
  margin-top: 3vh;

  .cardReview {
    width: 80%;
    height: 60vh;
    margin: 0 auto;
  }

  .card {
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2vh;
    width: 25vw;
    height: 28vh;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    border-style: solid;
    border-width: 5px;
    border-color: #fff;

    &.fullSize {
      width: 100%;
      height: 100%;
    }

    &.highlight {
      border: 15px solid #008000;
    }

    .cardImage {
      padding: 5%;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
