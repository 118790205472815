@import "./../../pages/App/vars.scss";

.repeatRow {
  text-align: center;
}

.cardContainer {
  cursor: move;
  height: 100%;
  text-align: center;
}

.card {
  margin: 0 auto;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
}
.cardImageContainer {
  height: 50%;
  margin: 2vmin;
}

.dropImageContainer {
  height: 30vh;
  padding: 10%;
}

.cardImage {
  max-height: 100%;
  max-width: 100%;
}
.cardText {
  height: 20%;
  .firstLetterBold::first-letter {
    font-weight: bold;
  }
  .text {
    margin-top: -2.2vh;
    font-size: 4vh;
  }
}

@keyframes greenToWhiteBgTransition {
  0% {
    background-color: $green;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes redToWhiteBgTransition {
  0% {
    background-color: $red;
  }
  100% {
    background-color: #fff;
  }
}

.redFlash {
  animation-name: redToWhiteBgTransition;
  animation-duration: 2s;
}

.greenFlash {
  animation-name: greenToWhiteBgTransition;
  animation-duration: 2s;
}

.topCard {
  height: 12vh;
}
.bottomCard {
  height: 22vh;
}
.cardText {
  height: 20%;
  .firstLetterBold::first-letter {
    font-weight: bold;
  }
  .text {
    margin-top: -2vh;
    font-size: 4vh;
  }
}

.audioRepeatContainer {
  padding-left: 10vw;
  padding-right: 10vw;
}
