.cardContainer {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 1.5vh;
}

.checkContainer {
  position: absolute;
  width: 100%;

  text-align: center;

  .clear {
    font-size: 30vh;
    color: #ff0000;
  }

  .check {
    font-size: 30vh;
    color: #008000;
  }
}
