.option {
  width: 100%;
  height: 100%;
}
.container {
  padding-left: 6vh;
  height: 100%;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1vh;
}

.card {
  cursor: pointer;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  width: 95%;
  text-align: center;
  padding: 5%;
  height: 95%;
  background-color: white;
}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
}

.img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.greenBorder {
  border-color: #1c7430;
  border-width: 15px;
}
