.cardContainer {
  text-align: center;
  margin-top: 3vh;

  .checkContainer {
    position: absolute;
    width: 100%;

    .clear {
      font-size: 30vh;
      color: #ff0000;
    }

    .check {
      font-size: 30vh;
      color: #008000;
    }
  }
  .imageClickOption {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 2vh;
  }
  .card {
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2vh;
    width: 25vw;
    height: 28vh;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    border-style: solid;
    border-width: 5px;
    border-color: #fff;

    &.fullSize {
      width: 100%;
      height: 100%;
    }

    &.highlight {
      border: 15px solid #008000;
    }

    .cardImage {
      padding: 5%;
      width: 100%;
      height: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
      }
    }
  }
}
