.card {
  cursor: pointer;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  width: 60%;
  text-align: center;
  padding: 1%;
  background-color: white;
  margin: 0 auto;
}

.img {
  max-height: 45vh;
  max-width: 100%;
}

.text {
  font-size: 5vh;
}
