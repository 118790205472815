@import "./../../../pages/App/vars.scss";

.completed,
.play {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 5vw;
  top: 14vh;
  margin-right: 5vw;
  z-index: 999;
}

.play {
  color: $purple;
}

.cardContainer {
  background-color: #fff;
  padding: 5%;
  border-radius: 2%;
  text-align: center;
  cursor: pointer;
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 50vh;

  .disabledOverlay {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    border-radius: 2%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
}

.lessonImageWrapper {
  margin: auto;
  text-align: center;
  position: relative;
  height: 25%;
  width: 100%;

  .lessonImage {
    height: 100%;
    margin: 0 auto;
  }

  .textLesson {
    position: absolute;
    margin: auto;
    top: 10%;
    font-size: 8vh;
    left: 0;
    right: 0;
    bottom: 0;
    color: #008000;
  }
}

.imagesContainer {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  height: 75%;
  .imageItem {
    width: 50%;
    height: 50%;
    &.singleItem {
      width: 100%;
      height: 100%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100%;
      padding: 1vh;
      margin: 0 auto;
    }
  }
}
