.container {
  padding-left: 20%;
  padding-right: 20%;
}

.context {
  margin-top: 2vh;
}

.question {
  margin-top: 3.6vh;
}

.dropBoxContainer {
  margin-top: 3.6vh;
}

.answers {
  margin-top: 3.6vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-basis: 100%;
}

.dropBox {
  margin-top: 3.6vh;
  background-color: #dde4ff;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  font-size: 5vh;
  position: relative;
  text-align: center;
}

.hidden {
  visibility: hidden;
}
