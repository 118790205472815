@import "../../../../pages/App/vars.scss";

.shapeImage {
  max-width: 6vw;
  flex: 1;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}
