.glenImage {
  max-height: 15vh;
  margin-bottom: 2vh;
}

.formSignIn {
  width: 30vw;
  margin: 0 auto;
}

.headerText {
  font-size: 6vh;
}
