.glenGuide {
  //width: 25vw;
  height: 45vh;
  //border: 1px solid black;
  //border-radius: 100%;
  //background-size: 60%;
  //background-color: #cccae0;
  //background-repeat: no-repeat;
  //background-position: center;
  z-index: 99999;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 15vw;
  outline: 0;
}
