.refreshImage {
  max-height: 6vh;
  max-width: 3vw !important;
  margin: auto;
  display: block;
  cursor: pointer;
}

.bookHolder {
  padding-left: 6vw;
  margin-top: 0vh;
  padding-right: 6vw;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
}

.controlContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  justify-content: center;
  width: 100%;
  & > * + * {
    margin-left: 50px;
  }
  & > * {
    color: #465685;
    opacity: 0.9;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
  }
}
.book {
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-transition: margin-left 0.2s ease-in-out;
  -moz-transition: margin-left 0.2s ease-in-out;
  -o-transition: margin-left 0.2s ease-in-out;
  -ms-transition: margin-left 0.2s ease-in-out;
  transition: margin-left 0.2s ease-in-out;

  :global(.page) {
    border-right: 1px solid #8c8c8c;
    border-left: 1px solid #8c8c8c;
    background-color: white;
    img {
      max-width: 100%;
      height: 100%;
    }
    .pageImage {
      height: 100%;
    }
    .singlePageImage {
      height: 62%;
    }
    .pageText {
      text-align: left;
      padding: 6%;
      font-size: 3.5vh;
      span {
        cursor: pointer;
      }
    }
    .singlePageText {
      text-align: center;
      padding-top: 1.5%;
      padding-bottom: 1.5%;
      padding-left: 6%;
      padding-right: 6%;
      font-size: 3vh;
      //font-size: calc(2px + 1.5vw);
      span {
        cursor: pointer;
      }
    }
  }

  .pageInner {
    background-image: url("assets/GLEN-WORLD_EBOOK-BACKGROUND-D1.jpg");
    .bookCover img {
      padding: 1%;
      width: 62%;
      height: 100%;
    }
    .selectedWord {
      color: #0613bf;
      /*font-weight: bold;*/
    }
    .transparentWord {
      opacity: 0;
      /*font-weight: bold;*/
    }
    .spacer {
      white-space: pre;
    }
  }

  .bookCover {
    //border: 8px solid #8c8c8c;
    width: 100%;
    height: 100%;
  }
  .credits {
    position: absolute;
    top: 10%;
    width: 100%;
  }

  .uiArrowNextPage,
  .uiArrowPreviousPage {
    position: absolute;
    /*top: 50%;*/
    top: calc(40%);
    right: -40px;
    background-position: 0 0;
    opacity: 0.8;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    transition: opacity 0.2s;
    cursor: pointer;
    color: #465685;
    border-radius: 50%;
    //border-width: thin;
    background-color: #ffffff;
    line-height: 0px;
    padding: -5%;
    //display: inline;
  }

  .uiArrowPreviousPage {
    background-position: 30px 0;
    right: auto;
    left: -40px;
  }

  .uiNext,
  .uiPrevious {
    position: absolute;
    /*top: 50%;*/
    top: calc(40%);
    right: 85px;
    background-position: 0 0;
    opacity: 0.8;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    transition: opacity 0.2s;
    cursor: pointer;
    color: #465685;
    border-radius: 50%;
    //border-width: thin;
    background-color: #ffffff;
    line-height: 0px;
    padding: -5%;
    //display: inline;
  }

  .uiPrevious {
    background-position: 30px 0;
    right: auto;
    left: 85px;
  }

  .depth {
    background-image: url("assets/pages-depth.png");
    position: absolute;
    right: 0px;
    top: -3px;
    width: 16px;
    height: 100.8%;
    overflow: hidden;
  }
  .leftdepth {
    background-image: url("assets/pages-depth.png");
    position: absolute;
    left: 0px;
    top: -3px;
    width: 16px;
    height: 100.8%;
    transform: scaleX(-1);
  }
}
